function multiClose(e) {
  if (e.detail && e.detail.form) {
    const background = e.detail.form.closest('.modal__bg');
    const header = document.querySelector('[data-header]');

    if (background) {
      let event = new Event('click');
      background.dispatchEvent(event);

      if (header) {
        event = new Event('closeMenu');
        header.dispatchEvent(event);
      }
    }
  }
}

function handleSuccessHandle(event) {
  multiClose(event);
}

document.addEventListener('success', (e) => {
  handleSuccessHandle(e);
});