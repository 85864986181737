function closeMenu(e) {
  const header = e.target;
  header.classList.remove('_open');
  document.body.classList.remove('_fixed');
}

window.addEventListener('load', () => {
  const header = document.querySelector('[data-header]');
  window.addEventListener('scroll', () => {
    if (window.pageYOffset >= 2) {
      header.classList.add('header_transform');
    } else {
      header.classList.remove('header_transform');
    }
  });

  const nav = document.querySelector('[data-nav]');
  const navParents = document.querySelectorAll('[data-nav-parent]');

  if (navParents) {
    navParents.forEach((navParent) => {
      // const navChildsContainers = navParent.querySelector('[data-nav-childs-container]');
      // const navChilds = navParent.querySelectorAll('[data-nav-childs]');

      const enterHandler = () => {
        const leaverHandler = () => {
          nav.classList.remove('_active');
          navParent.addEventListener('mouseenter', enterHandler);
          navParent.removeEventListener('mouseleave', leaverHandler);
        };

        nav.classList.add('_active');
        navParent.addEventListener('mouseleave', leaverHandler);
        navParent.removeEventListener('mouseenter', enterHandler);

        navParents.forEach((item) => {
          item.style.zIndex = 0;
        });
        navParent.style.zIndex = 100;
      };

      navParent.addEventListener('mouseenter', enterHandler);
    });
  }
});

function openMenu(e) {
  const header = e.target;
  header.classList.add('_open');
  document.body.classList.add('_fixed');
}

function initHamburger(header) {
  const hamburger = document.querySelector('[data-hamburger]');
  if (hamburger) {
    hamburger.addEventListener('click', (e) => {
      e.preventDefault();
      const event = header.classList.contains('_open') ? new Event('closeMenu') : new Event('openMenu');
      header.dispatchEvent(event);
    });
  }
}

function initHeader() {
  const header = document.querySelector('[data-header]');
  if (header) {
    initHamburger(header);

    header.addEventListener('closeMenu', closeMenu);
    header.addEventListener('openMenu', openMenu);
  }

  const menuItems = header.querySelectorAll('[data-scroll-link]');
  menuItems.forEach((menuItem) => {
    menuItem.addEventListener('click', (e) => {
      header.dispatchEvent(new Event('closeMenu'));
    })
  })
}

document.addEventListener('DOMContentLoaded', initHeader);